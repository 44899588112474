<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" id="itemdialogs" style="width: 600px; overflow: auto; max-height: 85%;">
      <div class="relative bg-white">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
                Add New Tax
            </div>
          </div>
          <div class="p-4">
            <div v-for="(tax, i) in taxlist" :key="i" class=" text-text2">
                <div class="mb-4">
                    <TextField
                    :inputId="'taxinputnameid'"
                    :textMaxlength="50"
                    :showcharLimit="true"
                    :fieldError="tax.nameErr !== ''"
                    :inputext="tax.taxName"
                    :placholderText="`Tax Name`"
                    :lableText="'Tax Name'"
                    :autoFocus="false"
                    @keyPressAction="tax.nameErr = ''"
                    @inputChangeAction="(data) => tax.taxName = data"  />
                    <p v-if="tax.nameErr !== ''" class="text-error heading-7">{{tax.nameErr}}</p>
                </div>
                <div class="mb-4 flex justify-between items-center">
                    <div>
                      <TextField
                      :inputId="`taxrateinputid`"
                      :inputext="tax.taxValue"
                      :placholderText="`Tax rate`"
                      :lableText="'Tax Rate %'"
                      :inputType="'number'"
                      :textRight="false"
                      :autoFocus="false"
                      :setReadOnly="false"
                      :inputTypeText="'number'"
                      :fieldError="tax.valueErr !== ''"
                      @keyPressAction="tax.valueErr = ''"
                      @inputChangeAction="(data) => tax.taxValue = data"/>
                        <p v-if="tax.valueErr !== ''" class="text-error heading-7">{{tax.valueErr}}</p>
                    </div>
                    <div >
                        <span v-if="taxlist.length > 1" @click="deleteTax(i)" class="text-error cursor-pointer heading-3">
                        <i class="fa-regular fa-trash-can"></i>
                        </span>
                    </div>
                </div>
                <div v-if="i < taxlist.length - 1" class="divider my-4"></div>
            </div>
            <div>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add More Tax'" @buttonAction="addMoreTax()"/>
            </div>
          </div>
        </div>
          <div class=" sticky -bottom-1 bg-white w-full flex justify-end pr-3 pt-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveItemsTax()"/>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  name: 'sendEmail',
  components: {
    Button,
    TextField,
  },
  data () {
    return {
        taxlist: [
            {
              taxId: 0,
              taxName: '',
              taxValue: '',
              isActive: true,
              nameErr: '',
              valueErr: ''
            }
        ],
    }
  },
  props: [],
   created() {
  },
  mounted () {
  },
  watch: {},
  methods: {
    bluNumberset (data, index) {
        if (data === '') {
            this.taxlist[index].taxValue = 0
        }
    },
    deleteTax (index) {
        this.taxlist.splice(index,1)
    },
    addMoreTax () {
        this.taxlist.push({
              taxId: 0,
              taxName: '',
              taxValue: 0,
              isActive: true,
              nameErr: '',
              valueErr: ''
            })
    },
    discard () {
      this.$root.$emit('addNewTaxHandler', true, [])
    },
    saveItemsTax () {
      let isValid = true
      let finalArr = []
      this.taxlist.forEach((el) => {
        el.taxValue = Number(el.taxValue)
        if (el.taxName.trim() === '') {
            isValid = false
            el.nameErr = 'Tax name is requied.'
        }
        if (el.taxValue <= 0) {
            isValid = false
            el.valueErr = 'Tax rate is requied.'
        }
        finalArr.push(
            {
              taxId: el.taxId,
              taxName: el.taxName,
              taxValue: el.taxValue,
              isActive: el.isActive,
            }
        )
      })
      if (isValid) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        MyJobApp.addNewTax(
          finalArr,
          response => {
            this.$root.$emit('addNewTaxHandler', true, response.Data)
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
        
    },
  },
  beforeDestroy () {
    // this.$root.$off('closeConfirmBoxDialog')
  }
}
</script>
<style scoped>

</style>