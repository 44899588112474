<template>
  <div class="mt-4 overflow-auto " :style="`height:` + cardHeight + `px;max-height:` + cardHeight + `px;overflow: auto; !important`">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">{{parseInt(this.$route.params.itemId) > 0 ? 'Edit Item' : 'Add Item'}}</h1>
      </div>
    </div>
    <div class="flex justify-center">
      <div class=" sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
        <div class=" card rounded-xl bg-white p-4 my-4">
          <div class="sm:grid grid-cols-2 gap-4 mt-2">
            <div class="mb-4">
              <TextField
              :inputId="'itemName'"
              :textMaxlength="50"
              :showcharLimit="true"
              :inputext="invObj.itemName"
              :placholderText="`Item Name`"
              :lableText="'Item Name'"
              :autoFocus="false"
              @keyPressAction="errObj.itemNameErr = ''"
              :fieldError="errObj.itemNameErr !== ''"
              @inputChangeAction="(data) => invObj.itemName = data"
              />
              <p v-if="errObj.itemNameErr !== ''" class="text-error heading-7">{{errObj.itemNameErr}}</p>
            </div>
            <div class="mb-4">
              <TextField
              :inputId="'itemCode'"
              :textMaxlength="50"
              :showcharLimit="true"
              :inputext="invObj.itemCode"
              :placholderText="`Item Code`"
              :lableText="'Item Code'"
              :autoFocus="false"
              @inputChangeAction="(data) => invObj.itemCode = data"
              />
            </div>
          </div>
          <div class="sm:grid grid-cols-2 gap-4 mt-2">
            <div class="mb-4">
              <TextField
               :inputId="`itemprice`"
               :inputext="invObj.itemPrice"
               :placholderText="`Item Price`"
               :lableText="'Item Price'"
               :inputType="'number'"
               :textRight="true"
               :autoFocus="false"
               :setReadOnly="false"
               :inputTypeText="'number'"
               @keyPressAction="errObj.itemPriceErr = ''"
              :fieldError="errObj.itemPriceErr !== ''"
               @inputChangeAction="(data) => invObj.itemPrice = data" />
<!-- 
              <NumberInput
               :inputId="'itemprice'"
               :inputext="invObj.itemPrice"
               :placholderText="`Item Price`"
               :lableText="'Item Price'"
               :inputType="'text'"
               :textRight="true"
               :autoFocus="false"
               @inputChangeAction="(data) => invObj.itemPrice = data" /> -->
              <p v-if="errObj.itemPriceErr !== ''" class="text-error heading-7">{{errObj.itemPriceErr}}</p>
            </div>
            <div class="mb-4">
              <TextField
              :inputId="'unit'"
              :textMaxlength="15"
              :showcharLimit="true"
              :inputext="invObj.itemUnit"
              :placholderText="`kg / nos / ton`"
              :lableText="'Unit'"
              :autoFocus="false"
              @inputChangeAction="(data) => invObj.itemUnit = data"
              />
            </div>
          </div>
          <div class="sm:grid grid-cols-2 gap-4">
            <div class="">
              <Dropdown
              :inputext="invObj.categoryName"
              :inputId="'categorySel'"
              :lableText="'Category'"
              :placholderText="`Select Category`"
              :autoFocus="false"
              :showArrow="true"
              :setReadOnly="false"
              :searchCallApi="true"
              :showPlus="false"
              :keyName="'categoryName'"
              :listId="'itemCategoryMasterId'"
              :items="categoryList"
              @inputChangeAction="callsearchCust"
              @selectNewForVal="selectCatValue"
              />
            </div>
          </div>
          <div class="my-4">
            <div class="heading-5" v-if="!addItemDesc">
              <span @click="addItemDesc = true" class="text-primary font-bold cursor-pointer">Add Description</span>
            </div>
            <TextAreaInput v-if="addItemDesc"
            :inputId="'invicenoteid'"
            :inputext="invObj.itemDescription"
            :placholderText="`Write Description here...`"
            :lableText="'Item Description'"
            :inputType="'text'"
            :autoFocus="false"
            :textMaxlength="1000"
            :showcharLimit="true"
            :cols="50"
            :rows="3"
            :setReadOnly="false"
            @inputChangeAction="(data) => invObj.itemDescription = data" 
            />
          </div>
        </div>
        <div class=" card rounded-xl bg-white p-4 my-4">
          <div class="text-text2 heading-5">Tax</div>
          <div v-for="(taxes, index) in finalTaxList" :key="index" :class="`flex ${index < finalTaxList.length - 1 ? 'border-b' : ''} py-2 heading-6`">
            <div class="w-full">
              <div class="flex justify-between">
                <div class="text-gray4 flex items-center">
                    <div class="pl-1 mr-4">
                        <span @click="deleteItemTax(taxes.taxId, taxes)" class="text-error cursor-pointer heading-3">
                          <i class="fa-regular fa-trash-can"></i>
                        </span>
                    </div>
                    <div>
                        {{taxes.taxName.substr(0, 20)}} 
                        <span v-if="taxes.taxName.length > 20">...</span>
                    </div>
                </div>
                <div>
                  <p class="text-gray4 text-right">{{taxes.taxValue}} %</p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-primary my-2"><span  @click.stop.prevent=" addTaxinput = true;" class="cursor-pointer">+ Add Tax</span></div>
            <div class="mb-4" v-if="addTaxinput">
              <div class=" bg-white">
                <MultiSelect 
                :inputext="searchForItem"
                :inputId="'itemtaxsearchid'"
                :lableText="'Select Tax'"
                :placholderText="`Search tax`"
                :autoFocus="true"
                :showArrow="true"
                :setReadOnly="false"
                :showselectAll="false"
                :searchCallApi="true"
                :showPlus="true"
                :keyName="'displayText'"
                :idKey="'taxId'"
                :sectionId="'taxlistsecid'"
                :listId="'taxlistid'"
                :selectedValues="selectedTaxList"
                :items="itemtaxList"
                @closeList="addTaxinput = false"
                @inputChangeAction="searchTaxes"
                @selectNewForVal="selectNewTaxes"
                @addNew="addNewTax"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-end  card rounded-xl bg-white p-4 my-4">
          <div class="text-rigth flex gap-2">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'"
              @buttonAction="discard()" />
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'"
              @buttonAction="createNewItem()" />
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
    <AddnewItemsTax v-if="addNewItemTax" />
  </div>
</template>
<script>
// import NumberInput from '@/View/components/number-text-field.vue'
import MultiSelect from '@/View/components/multipleSelectDrop.vue'
import AddnewItemsTax from '../components/AddnewItemsTax.vue'
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import Dropdown from '@/View/components/dropdown.vue'
import TextField from '@/View/components/textfield.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    Button,
    TextAreaInput,
    // NumberInput,
    AddnewItemsTax,
    MultiSelect,
    TextField,
    Dropdown,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      addItemDesc: false,
      cardHeight: 0,
      searchForItem: '',
      selectedTaxList: [],
      addNewItemTax: false,
      itemtaxList: [],
      finalTaxList: [],
      addTaxinput: false,
      invObj: {
        itemMasterId: 0,
        itemCategoryMasterId: 0,
        itemCode: '',
        itemName: '',
        itemDescription: '',
        itemPrice: '',
        itemUnit: '',
        isActive: true,
        itemScore: 0,
        categoryName: '',
        itemTaxes: [],
      },
      categoryList: [],
      searchApitext: '',
      getCustList: () => { },
      getTaxesDataList: () => {},
      errObj: {
        itemNameErr: '',
        itemPriceErr: '',
        dateError: ''
      }
    };
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  computed: {
  },
  watch: {
    invObj: {
      handler () {
        if (this.invObj.itemName !== '') {
          this.errObj.itemNameErr = ''
        }
        if (this.invObj.itemPrice !== '') {
          this.errObj.itemPriceErr = ''
        }
      },
      deep: true
    }
  },
  mounted() {
    if (parseInt(this.$route.params.itemId) > 0) {
      this.getItemSDetail ()
    }
    this.getCustList = this.debounce(function () {
      this.getCatogeryList()
    }, 500)
    this.getCatogeryList()
    this.getTaxesDataList = this.debounce(function () {
      this.getTaxesList()
    }, 500)
    this.getTaxesList()
    this.$root.$on('addNewTaxHandler', (response, newTax) => {
      if (response && newTax.length > 0) {
        newTax.map((data) => {
            this.selectedTaxList.push({...data, displayText: data.taxName + ' ' +  `(${data.taxValue}%)`})
            this.finalTaxList.push(data)
        }) 
        setTimeout(() => {
            this.getTaxesList()
        }, 500);
      }
      this.addNewItemTax = false
    })
  },
  methods: {
    createNewItem() {
      let isValid = true
      if (this.invObj.itemName === '') {
        isValid = false
        this.errObj.itemNameErr = 'Item Name is Required'
      }
      if (this.invObj.itemPrice === '') {
        isValid = false
        this.errObj.itemPriceErr = 'Item Price is Required'
      }
      this.invObj.itemTaxes = []
      for (let index = 0; index < this.finalTaxList.length; index++) {
        this.invObj.itemTaxes.push({
          taxId: this.finalTaxList[index].taxId
        })
      }
      if (isValid) {
        this.invObj.itemPrice = parseFloat(this.invObj.itemPrice)
        if (parseInt(this.$route.params.itemId) === 0) {
          this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
          MyJobApp.settingAddNewItem(
            this.invObj,
            response => {
              this.$router.push({ name: 'SettingItemDetails', params: { itemId: response.Data }})
              this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
              this.$store.dispatch('SetLoader', { status: false, message: '' })
            },
            (error) => {
              this.$store.dispatch('SetAlert', { showAlert: true, message: error.message, color: 'error' })
              this.$store.dispatch('SetLoader', { status: false, message: '' })
            }
          )
        } else if (parseInt(this.$route.params.itemId) > 0) {
          this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
          MyJobApp.settingEditExistItem(
            this.invObj,
            response => {
              this.$router.push({ name: 'SettingItemDetails', params: { itemId: response.Data }})
              this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
              this.$store.dispatch('SetLoader', { status: false, message: '' })
            },
            (error) => {
              this.$store.dispatch('SetAlert', { showAlert: true, message: error.message, color: 'error' })
              this.$store.dispatch('SetLoader', { status: false, message: '' })
            }
          )
        }
      }
    },
    selectCatValue(data) {
      this.invObj.itemCategoryMasterId = data.itemCategoryMasterId
      this.invObj.categoryName = data.categoryName
    },
    discard() {
      this.$router.push({ name: 'Items', query: { activeTab: 0 } })
    },
    callsearchCust(text) {
      this.searchApitext = text
      this.getCustList()
    },
    searchTaxes  (searchText) {
      this.searchForItem = searchText
      this.getTaxesDataList ()
    },
    selectNewTaxes (list) {
      this.selectedTaxList = list
      this.finalTaxList = list
    },
    addNewTax () {
        this.addNewItemTax = true
    },
    getItemSDetail() {
      console.log("call", this.$route);
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      MyJobApp.getSettingItemsDetail(
        parseInt(this.$route.params.itemId),
        (response) => {
          this.invObj.itemMasterId = response.Data.itemMasterId
          this.invObj.itemCategoryMasterId = response.Data.itemCategoryMasterId
          this.invObj.itemCode = response.Data.itemCode
          this.invObj.itemName = response.Data.itemName
          this.invObj.itemDescription = response.Data.itemDescription
          if (this.invObj.itemDescription !== '') {
            this.addItemDesc = true
          } else {
            this.addItemDesc = false
          }
          this.invObj.itemPrice = response.Data.itemPrice
          this.invObj.itemUnit = response.Data.itemUnit
          this.invObj.isActive = response.Data.isActive
          this.invObj.itemScore = response.Data.itemScore
          this.invObj.categoryName = response.Data.categoryName
          if (response.Data.itemTaxes !== null) {
            response.Data.itemTaxes.map((data) => {
              this.selectedTaxList.push({...data, displayText: data.taxName + ' ' +  `(${data.taxValue}%)`})
              this.finalTaxList.push(data)
            }) 
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    deleteItemTax (taxId, value) {
      for (let index = 0; index < this.finalTaxList.length; index++) {
        if (this.finalTaxList[index].taxId === value.taxId) {
          this.finalTaxList.splice(index, 1)
        }
      }
    },
    getCatogeryList() {
      MyJobApp.getSettingCategoryList(
        0,
        20,
        this.searchApitext,
        '',
        '',
        response => {
          this.categoryList = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.$store.dispatch('SetLoader', { status: false, message: '' })
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', { status: false, message: '' })
        }
      )
    },
    getTaxesList () {
        MyJobApp.getTaxList(
        0,
        20,
        this.searchForItem,
        'DESC',
        'taxName',
        response => {
          let templist = []
          if (response.Data.tableRow !== null) {
            response.Data.tableRow.map((data) => {
                templist.push({...data, displayText: data.taxName + ' ' +  `(${data.taxValue}%)`})
            }) 
          }
          this.itemtaxList = templist
        },
        error => {
          console.error(error)
        }
      )
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 80
    },
  },
  beforeDestroy() {
    this.$root.$off("addCustomerHandler");
    this.$root.$off("itemSelectionHandler");
  },
};
</script>
<style scoped></style>